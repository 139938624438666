html, body {
  height: 100%;
  background-color: #0f1b2a;
  color: white;
}

#app {
  height: 100%;
}

.spinner {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}